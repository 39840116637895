<template>
  <card class="instruction">
    <h3 class="instruction__title">{{ title }}</h3>
    <vimeo
      v-if="isVideo"
      :url="video.url"
      :id="video.id"
      class="instruction__video"
      :disabled="recording"
      key="video"
    />
    <vimeo
      v-else-if="isExample"
      :url="example.url"
      :id="example.id"
      class="instruction__video"
      :disabled="recording"
      key="example"
    />

    <ol class="list" v-else>
      <li>
        Sit upright with your mouth ~14 inches (36 cm) from the microphone
      </li>
      <li>
        Press <span class="instruction__success"><b>"Record"</b></span>
      </li>
      <li>
        Inhale as deeply as you can
      </li>
      <li>
        Sustain the “ahh” sound for as long as you can at your normal speaking
        volume
      </li>
      <li>
        Press <span class="instruction__danger"><b>"Stop"</b></span> after your
        lungs are completely empty
      </li>
    </ol>

    <div class="instruction__links">
      <a
        v-for="el in links"
        :key="el.key"
        @click.prevent="changeView(el.key)"
        href="#"
        class="instruction__link"
      >
        {{ el.link }}
      </a>
    </div>
  </card>
</template>

<script>
import Instructions from "@/mixins/instructions";
import Card from "@/components/card";
import { isIOS } from "@/utils/system";

export default {
  components: { Card },

  mixins: [Instructions],

  mounted() {
        if (isIOS){
          this.changeView("text");
        }
  },

  data: () => ({
    video: {
      url: "https://vimeo.com/423881170/7ca8d182e3",
      id: "423881170",
    },
    example: {
      url: "https://vimeo.com/424048034/9a82d636eb",
      id: "424048034",
    },
  }),
};
</script>
