<template>
  <app-layout class="page__recorder-outside">
    <template v-slot:space>
      <remember class="record__remember" v-if="recording">
        <li>Inhale as deeply as you can!</li>
        <li>Sustain "Ahh" as long as you can!</li>
      </remember>
      <div v-else-if="audioSource" class="record__audio-description">
        Review your recording (optional). If you hear background noise or did
        not exert maximum effort, delete and record again.
      </div>
      <instructions v-else />
      <record-shared
        @send-voice="sendHandler"
        :disabled="loading"
        :min-duration="minDuration"
        :voiceKey="voiceKey"
        :route="currentRoute"
        inline
      />
    </template>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/appLayout";
import Instructions from "./components/instructions";
import RecordShared from "@/components/record";
import RecordMixin from "@/mixins/record";
import RouteMixin from "@/mixins/routing";
import Remember from "@/components/remember";
import logVisiting from "@/mixins/logVisiting";

import {
  ROUTE_SUSTAINED_AAH,
  ROUTE_THREE_DEEP_BREATHS,
  RECORD_MIN_DURATION_AAH,
} from "@/constants";

export default {
  name: "SustainedAah",
  components: { AppLayout, Instructions, RecordShared, Remember },
  mixins: [RecordMixin, RouteMixin, logVisiting],

  data: () => ({
    voiceKey: "audio2",
    currentRoute: ROUTE_SUSTAINED_AAH,
    nextRoute: ROUTE_THREE_DEEP_BREATHS,
    type: "record",
    minDuration: RECORD_MIN_DURATION_AAH,
  }),
};
</script>
