<template>
  <card class="disclaimer">
    <h3 class="title">Remember</h3>
    <ol class="list list_white-circle">
      <slot />
    </ol>
  </card>
</template>

<script>
import Card from "@/components/card";

export default {
  components: { Card },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";

.title {
  text-transform: uppercase;
  color: $color-blue;
}
</style>
